import React, {useState} from "react";
import {Checkbox,} from "@mui/material";
import {TaskAddModal} from "./components/TaskAddModal";
import {Link} from "react-router";
import {Icon} from "../../../../components/Icon";
import dayjs from "dayjs";

export const TaskListView = (props) => {
  const {
    profile,

    tasks,

    isModalAdd,
    setIsModalAdd,

    modalEdit,
    setModalEdit,

    isUpdate,
    setIsUpdate,

    checkOrUncheckTask,
  } = props;

  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <>
      <div
        style={{
          padding: 16,
          backgroundColor: "white",
          boxShadow: "0 5px 24px 16px rgba(0, 0, 0, 0.05)",
          borderRadius: 11,
          height: "100%",
          // width: "calc(68% - 16px)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "stretch",
          // marginRight: 16
        }}
      >
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          height: 24
        }}>
          <Link to={"/calendar-task"} style={{
            textDecoration: "none"
          }}>
            <span style={{
              color: '#1982F4',
              fontSize: 18,
              fontWeight: "bold"
            }}>
             {profile.language_code === "ru" ? "Задачи дня" : "Tasks of the day"}
            </span>
          </Link>
          <div style={{
            display: "flex",
            justifyContent: "end"
          }}>
            <div style={{
              cursor: "pointer",
              marginRight: 4,
              height: 24
            }}
                 onClick={() => setIsModalAdd(true)}>
              <Icon name={'icon-add-circle'} size={24} color={'#A4A4A4'}/>
            </div>
 
            <div style={{
              cursor: "pointer",
              height: 24
            }}>
              <Link to={"/calendar-task"}>
                <Icon name={'icon-calendar'} size={24}/>
              </Link>
            </div>
          </div>
        </div>

        <div style={{
          margin: "8px 0",
          backgroundColor: "#c4c4c4",
          height: 1
        }}/>

        <div style={{
          overflowY: "auto",
          height: "100%",
        }}>
          {!!tasks && tasks.map((task) => (
            <div
              style={{
                overflow: 'hidden',
                boxSizing: 'border-box',
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              key={task.id}
            >
              <Checkbox
                checked={task.checked}
                color={"success"}
                onChange={() => checkOrUncheckTask(task.id, task.checked)}
                style={{
                  padding: "2px 0",
                  marginRight: 4
                }}
              />
              <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: 14,
                    color: "#676D74"
                    // backgroundColor: {task.priority != ''  ? 'green' : 'yellow'}
                  }}
                  onClick={() => {
                    setModalEdit(task);
                    setOpenEditModal(true)
                  }}
              >
                {!!task.datetime && (
                    <span style={{
                      marginRight: 4,
                      fontWeight: 700
                    }}>{dayjs(task.datetime).format(profile.language_code === "ru" ? 'HH:mm' : 'hh:mm A')}</span>
                )}

                <span style={{backgroundColor: task.priority === 'HIGH' ? 'rgba(254, 232, 210, 1)' : '#'}}>{task.title}</span>

              </span>
            </div>
            ))}
        </div>
      </div>

      <TaskAddModal
        open={isModalAdd}
        successfulResponse={() => setIsUpdate(!isUpdate)}
        handleClose={() => setIsModalAdd(false)}
      />

      <TaskAddModal
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        successfulResponse={() => setIsUpdate(!isUpdate)}
        task={modalEdit}
      />
    </>
  );
}
