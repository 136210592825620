import React from "react";
import {Checkbox, Divider} from "@mui/material";
import {Link} from "react-router";
import {Icon} from "../../../../components/Icon";
import {HabitAddModal} from "./components/HabitAddModal";
import {HabitEditModal} from "./components/HabitEditModal";

export const HabitListView = (props) => {
  const {
    profile,

    habits,

    modalEdit,
    setModalEdit,

    handleCheckboxChange,

    isModalAdd,
    setIsModalAdd,

    isUpdate,
    setIsUpdate,
  } = props;

  return (
    <>
      <div
        style={{
          padding: 16,
          backgroundColor: "white",
          boxShadow: "0 5px 24px 16px rgba(0, 0, 0, 0.05)",
          borderRadius: 11,
          height: "100%",
          // width: "calc(68% - 16px)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "stretch",
          // marginRight: 16
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 24,
          }}
        >
          <Link to={"/calendar-habits"} style={{
            textDecoration: "none"
          }}>
            <span style={{
              color: '#1982F4',
              fontSize: 18,
              fontWeight: "bold"
            }}>
            {profile.language_code === "ru"
              ? "Привычки дня"
              : "Habits of the day"}
            </span>
          </Link>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                cursor: "pointer",
                marginRight: 4,
                height: 24,
              }}
              onClick={() => setIsModalAdd(true)}
            >
              <Icon name={"icon-add-circle"} size={24} color={"#A4A4A4"}/>
            </div>

            <div
              style={{
                cursor: "pointer",
                height: 24,
              }}
            >
              <Link to={"/calendar-habits"} style={{textDecoration: "none"}}>
                <Icon name={"icon-calendar"} size={24}/>
              </Link>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: "8px 0",
            backgroundColor: "#c4c4c4",
            height: 1,
          }}
        />

        <div
          style={{
            overflowY: "auto",
            height: "100%",
          }}
        >
          {!!habits &&
            habits.map((habit) => (
              <div
                style={{
                  overflow: "hidden",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                key={habit.uuid}
              >
                <Checkbox
                  checked={!!habit.isChecked}
                  color={"success"}
                  onChange={() => handleCheckboxChange(habit.uuid)}
                  style={{
                    padding: "2px 0",
                    marginRight: 4,
                  }}
                />
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: 14,
                    color: "#676D74",
                  }}
                  onClick={() => setModalEdit(habit)}
                >
                  <span>{habit.title}</span>
                </span>
              </div>
            ))}
        </div>
      </div>

      <HabitAddModal
        open={isModalAdd}
        successfulResponse={() => setIsUpdate(!isUpdate)}
        handleClose={() => setIsModalAdd(false)}
      />

      <HabitEditModal
        open={!!modalEdit}
        handleClose={() => setModalEdit(null)}
        object={modalEdit}
        successfulResponse={() => setIsUpdate(!isUpdate)}
      />
    </>
  );
}
