import React, {useState} from "react";
import {useProfile} from "../../../../hooks/useProfile";
import {Link} from "react-router";
import {Icon} from "../../../../components/Icon";
import {WaterGlasses} from "./WaterGlasses";
import dayjs from "dayjs";

export const WaterList = () => {
  const {profile} = useProfile();
  const [waterMarksQuantity, setWaterMarksQuantity] = useState(0);

  return (<>
    <div
      style={{
        padding: 16,
        backgroundColor: "white",
        boxShadow: "0 5px 24px 16px rgba(0, 0, 0, 0.05)",
        borderRadius: 11,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "stretch",
      }}
    >
      <div
        style={{
          display: "flex", justifyContent: "space-between", height: 24,
        }}
      >
        <Link to={"/calendar-habits"} style={{
          textDecoration: "none"
        }}>
            <span style={{
              color: '#1982F4', fontSize: 18, fontWeight: "bold"
            }}>
            {profile.language_code === "ru" ? "Выпито за день" : "Water drunk per day"}
            </span>
        </Link>
        <div
          style={{
            display: "flex", justifyContent: "end",
          }}
        >
          <div
            style={{
              cursor: "pointer", height: 24,
            }}
          >
            <Link to={"/calendar-water"} aria-disabled={true} style={{textDecoration: "none"}}>
              <Icon name={"icon-calendar"} size={24}/>
            </Link>
          </div>
        </div>
      </div>

      <div
        style={{
          margin: "8px 0", backgroundColor: "#c4c4c4", height: 1,
        }}
      />

      <WaterGlasses selectedDate={dayjs()} waterMarksQuantity={waterMarksQuantity}
                    setWaterMarksQuantity={setWaterMarksQuantity}/>

      <p style={{
        fontWeight: 'bold', textAlign: 'center', color: '#676D74', margin: 0,
      }}
      >
        {profile.language_code === 'ru' ? <span>Выпито {waterMarksQuantity} из 10</span> :
          <span>Drunk {waterMarksQuantity} of 10</span>}<br/>
      </p>

      <p style={{
        textAlign: 'center', color: '#676D74', margin: 0,
      }}
      >
        {profile.language_code === 'ru' ? <span>{250 * waterMarksQuantity} мл из 2500 мл</span> :
          <span>{250 * waterMarksQuantity} ml of 2500</span>}
      </p>
    </div>
  </>)
}
