import {useEffect, useState} from "react";
import {useProfile} from "../../../../hooks/useProfile";
import {DATE_FORMAT} from "../../../../api";
import TaskApi, {sortTasks} from "../../../../api/task";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale)

export const useTaskList = () => {
  const {profile, auth, initData} = useProfile();

  const taskApi = new TaskApi({profile, auth, initData})
  const dateNow = dayjs()

  const [tasks, setTasks] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(null);

  useEffect(() => {
    taskApi.getList({
      date_from: dateNow.startOf('day').format(DATE_FORMAT),
      date_to: dateNow.startOf('day').add(1, 'day').format(DATE_FORMAT),
    }).then((response) => {
      setTasks(response.data.data.sort(sortTasks));
    });
  }, [isUpdate]);

  const checkOrUncheckTask = (taskId, isChecked) => {
    (isChecked ? taskApi.uncheck(taskId) : taskApi.check(taskId)).then(() => {
      setIsUpdate(!isUpdate);
    });
  };

  return {
    profile,

    tasks,

    isModalAdd,
    setIsModalAdd,

    modalEdit,
    setModalEdit,

    isUpdate,
    setIsUpdate,

    checkOrUncheckTask,
  }
}
