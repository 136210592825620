import {useEffect, useState} from "react";
import {ROOT_URL} from "../../../../config/url";
import moment from "moment";
import {useProfile} from "../../../../hooks/useProfile";
import axios from "axios";

export const useHabitList = () => {
  const {profile, auth, initData} = useProfile();
  const [habits, setHabits] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [modalEdit, setModalEdit] = useState(null);
  const [isModalAdd, setIsModalAdd] = useState(false);

  useEffect(() => {
    axios.get('/api/v1/habits/day', {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth?.auth_date,
        'X-Telegram-Hash': auth?.hash,
        'X-Telegram-Init-Data': btoa(String(initData)),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      }, params: {
        date: moment().format("YYYY-MM-DD")
      }
    }).then((response) => {
      setHabits(response.data.data.map((habit) => ({
        ...habit,
        isChecked: habit.habit_marks.some((mark) => moment(mark.date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")),
      })));
    });
  }, [isUpdate]);

  const handleCheckboxChange = (habitUuid) => {
    axios.post('/api/v1/habit/' + habitUuid + '/check', {
      date: moment().format('YYYY-MM-DD'),
    }, {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth?.auth_date,
        'X-Telegram-Hash': auth?.hash,
        'X-Telegram-Init-Data': btoa(String(initData)),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      setIsUpdate(!isUpdate)
    });
  };

  return {
    profile,
    habits,
    isModalAdd,
    setIsModalAdd,
    isUpdate, setIsUpdate,
    modalEdit, setModalEdit,
    handleCheckboxChange,
  }
}
