import React, {useEffect, useState} from 'react'
// import {ReactComponent as IconDollar} from './icons/icon-dollar.svg'
import {ReactComponent as IconCalendar} from './icons/icon-calendar.svg'
import {ReactComponent as IconArchiveDown} from './icons/icon-archive-down.svg'
import {ReactComponent as IconAddCircle} from './icons/icon-add-circle.svg'
import {ReactComponent as IconUser} from './icons/icon-user.svg'
import {ReactComponent as IconCheckCircle} from './icons/icon-check-circle.svg'
import {ReactComponent as IconUndoLeft} from './icons/icon-undo-left.svg'
import {ReactComponent as IconEraser} from './icons/icon-eraser.svg'
import {ReactComponent as IconRoundArrowLeft} from './icons/icon-round-arrow-left.svg'
// import {ReactComponent as IconTelegram} from './icons/icon-telegram.svg'
import {ReactComponent as IconLogoWorky} from './icons/logo-worky.svg'
import {ReactComponent as IconCloseCircle} from './icons/icon-close-circle.svg'
import {ReactComponent as IconChevronLeft} from './icons/chevron-left.svg'
import {ReactComponent as IconChevronRight} from './icons/chevron-right.svg'
// import {ReactComponent as FirstPage} from './icons/page1.svg'
// import {ReactComponent as FirstDot} from "./icons/first-dot.svg";
// import {ReactComponent as SecondDot} from './icons/second-dot.svg'
import {ReactComponent as TelegramIcon} from './icons/telegram.svg'
import {ReactComponent as IconHuman} from './icons/user-logo.svg'
// import {ReactComponent as ThirdDot} from './icons/third-dot.svg'
import {ReactComponent as IconWaterGlassFull} from './icons/icon-water-glass-full.svg'
import {ReactComponent as IconWaterGlassEmpty} from './icons/icon-water-glass-empty.svg'

export const useIcon = (props) => {
  const [view, setView] = useState(null)

  useEffect(() => {
    return () => {
      setView(null)
    }
  }, [])

  useEffect(() => {
    setView(getView(props.name, props.color, props.size, props.width, props.height))
  }, [props.name, props.color, props.size])

  const getElement = (name) => {
    switch (name) {
      // case 'icon-dollar':
      //   return IconDollar;
      case 'icon-calendar':
        return IconCalendar;
      case 'icon-archive-down':
        return IconArchiveDown;
      case 'icon-add-circle':
        return IconAddCircle;
      case 'icon-user':
        return IconUser;
      case 'icon-check-circle':
        return IconCheckCircle;
      case 'icon-undo-left':
        return IconUndoLeft;
      case 'icon-eraser':
        return IconEraser;
      case 'icon-round-arrow-left':
        return IconRoundArrowLeft;
      // case 'icon-telegram':
      //   return IconTelegram;
      case 'logo-worky':
        return IconLogoWorky;
      case 'icon-close-circle':
        return IconCloseCircle;
      case 'chevron-left':
        return IconChevronLeft;
      case 'chevron-right':
        return IconChevronRight;
      // case 'first-page':
      //   return FirstPage;
      // case 'first-dot':
      //   return FirstDot;
      // case 'second-page':
      //   return SecondPage;
      // case 'second-dot':
      //   return SecondDot;
      case 'telegram-icon':
        return TelegramIcon;
      case 'user-logo':
        return IconHuman
      // case 'third-dot':
      //   return ThirdDot;
      case 'icon-water-glass-full':
        return IconWaterGlassFull;
      case 'icon-water-glass-empty':
        return IconWaterGlassEmpty;

      default:
        return null
    }
  }

  const getIcon = (Icon, color, width, height) => (
    <Icon fill={color} width={width} height={height}/>
  )

  const getView = (name, color, size, widthSize, heightSize) => {
    const width = String(!!widthSize ? widthSize : size) + 'px'
    const height = String(!!heightSize ? heightSize : size) + 'px'

    return !!getElement(name) && getIcon(getElement(name), color, width, height)
  }

  return {
    size: props.size,
    view
  }
}
