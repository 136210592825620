import React, {useRef, useState} from "react";
import {Link} from "react-router";
import {useProfile} from "../../hooks/useProfile";
import {Icon} from "../../components/Icon";
import style from "../CalendarTask/CalendarTask.module.scss"; // используем стиль из другой директории, возможнло нужно его вынести в общие стили. или как то по другому оформить
import {CustomCalendar} from "../CalendarTask/CalendarTask";
import dayjs from "dayjs";
import {WaterGlasses} from "../Dashborad/components/WaterList/WaterGlasses";

export const CalendarWater = () => {
  const {profile, auth, initData} = useProfile();
  const [waterMarksQuantity, setWaterMarksQuantity] = useState(0);

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const currentDateRef = useRef(dayjs());

  return <>
    <div className={style.calendarContainer}>
      <div className={style.calendarMenu}>
        <div className={style.calendarMenu__cursor}>
          <Link to={"/dashboard"}>
            <Icon
              name={"icon-round-arrow-left"}
              size={24}
              color={"#A4A4A4"}
            />
          </Link>
        </div>
        <span className={style.calendarMenu__text}>
            {profile.language_code === "ru" ? "Вода" : "Water"}
          </span>
      </div>
      <CustomCalendar
        selectedDate={selectedDate}
        languageCode={profile.language_code}
        taskCounts={0}
        onDateChange={setSelectedDate}
      />
      <div
        style={{
          border: currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "2px solid #FF713C" : "2px solid #c4c4c4",
          color: currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "#FF713C" : "#202020",
          marginBottom: "15px",
        }}
        className={style.calendarDateCurrent}
      >
        <div
          style={{
            display: "flex", justifyContent: "center",
          }}
        >
            <span style={{marginRight: 4}}>
              {profile.language_code === "ru" ? currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "Сегодня, " : "" : currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "Today is " : ""}
            </span>
          {selectedDate.format("DD.MM.YYYY")}
        </div>
      </div>

      <WaterGlasses selectedDate={selectedDate} waterMarksQuantity={waterMarksQuantity}
                    setWaterMarksQuantity={setWaterMarksQuantity}/>

      <p style={{
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#676D74',
        marginBottom: '10px',
      }}
      >
        {profile.language_code === 'ru' ? <span>Выпито {waterMarksQuantity} из 10</span> : <span>Drunk {waterMarksQuantity} of 10</span>}<br/>
      </p>

      <p style={{
        textAlign: 'center',
        color: '#676D74',
        margin: 0,
      }}
      >
        {profile.language_code === 'ru' ? <span>{250 * waterMarksQuantity} мл из 2500 мл</span> : <span>{250 * waterMarksQuantity} ml of 2500</span>}
      </p>

      <p style={{
        textAlign: 'center',
        fontSize: '10px',
        color: '#676D74',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
      }}
      >
        {profile.language_code === 'ru'
          ? 'Рекомендуется пить 2-2,5 литра воды в день, но Worky не обязывает вас следовать этим указаниям. Вы самостоятельно решаете, сколько воды вам требуется ежедневно.'
          : 'Suggest drinking 2-2.5 liters of water per day, but Worky does not oblige you to these requirements. You decide how much water you need daily.'
        }
      </p>
    </div>
  </>
}
