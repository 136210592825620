import React, {useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from "@mui/material";
import {useProfile} from "../../../../../hooks/useProfile";
import {Icon} from "../../../../../components/Icon";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {renderMultiSectionDigitalClockTimeView} from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {DatePicker} from "@mui/x-date-pickers";
import style from "./TaskAddModal.module.scss";
import TaskApi from "../../../../../api/task";
import {DATE_FORMAT} from "../../../../../api";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {DateTime} from "luxon";

dayjs.extend(utc);
dayjs.extend(timezone);

export const TaskAddModal = ({open, successfulResponse, handleClose, selectedDate, task}) => {
  const {profile, auth, initData} = useProfile();

  const taskApi = new TaskApi({profile, auth, initData})

  let dateNow = selectedDate ?? dayjs().hour(0).minute(0).second(0)

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [date, setDate] = useState(dateNow);
  const [luxonDate, setLuxonDate] = useState(DateTime.fromJSDate(date.toDate()));
  const [priority, setPriority] = useState("LOW");
  const [reminderEnabled, setReminderEnabled] = useState(false);
  const [timeEnabled, setTimeEnabled] = useState(false);
  const [reminder, setReminder] = useState(15 * 60);
  const [dateTime, setDateTime] = useState(dateNow.hour(0).minute(0).second(0));

  const validateTask = () => {
    if (!title) {
      setTitleError('Название не может быть пустым')
      return false
    }

    return true
  }

  useEffect(() => {
    if (titleError && title) {
      setTitleError('')
    }
  }, [title]);

  const handleSubmit = () => {
    if (!validateTask()) {
      return
    }

    (task
      ? taskApi.edit(task.id, {
          title: title,
          date: date.hour(0).minute(0).second(0).format(DATE_FORMAT),
          datetime: timeEnabled ? dateTime.format(DATE_FORMAT) : null,
          priority: priority,
          remind_before_seconds: timeEnabled ? (reminderEnabled ? reminder : null) : null,
          is_datetime_with_timezone: true,
        })
      : taskApi.add({
          title: title,
          date: date.hour(0).minute(0).second(0).format(DATE_FORMAT),
          datetime: timeEnabled ? dateTime.format(DATE_FORMAT) : null,
          priority: priority,
          remind_before_seconds: timeEnabled ? (reminderEnabled ? reminder : null) : null,
          is_datetime_with_timezone: true,
        })
    ).then((response) => {
        successfulResponse(response.data.data);
        handleClose();
      })
  };

  const setDatesFromLuxonDate = (luxonDate) => {
    if (!luxonDate.isValid) {
      luxonDate = selectedDate ? DateTime.fromJSDate(selectedDate.toDate()) : DateTime.now()
    }

    setLuxonDate(luxonDate)
    setDate(dayjs(luxonDate.toJSDate()))
  }

  useEffect(() => {
    if (!task) {
      return
    }

    setTitle(task.title)
    setDate(dayjs(task.date))
    setLuxonDate(DateTime.fromJSDate(date.toDate()))
    setPriority(task.priority)
    setReminderEnabled(!!task.remind_before_seconds)
    setReminder(task.remind_before_seconds ?? 15 * 60)
    setTimeEnabled(!!task.datetime)
    setDateTime(dayjs(task.datetime ?? task.date))
  }, [task]);

  useEffect(() => {
    if (!reminderEnabled) {
      setReminder(15 * 60)
    }
  }, [reminderEnabled]);

  useEffect(() => {
    if (!timeEnabled) {
      setReminderEnabled(false)
    }
  }, [timeEnabled]);

  useEffect(() => {
    if (!selectedDate) {
      return
    }

    setDate(selectedDate)
    setLuxonDate(DateTime.fromJSDate(selectedDate.toDate()))
    setDateTime(selectedDate.hour(0).minute(0).second(0))
  }, [selectedDate]);

  return (
    <Dialog open={open} onClose={handleClose} style={{
      borderRadius: 21
    }}>
      <DialogTitle style={{
        fontSize: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 16px 0"
      }}>
        <div style={{
          height: 24,
          color: "#1982F4"
        }}>
          {task ? profile.language_code === 'ru' ? `Редактировать задачу` : `Edit task` : profile.language_code === 'ru' ? `Добавить задачу` : `Add task`}
        </div>
        <div style={{
          height: 24,
          cursor: "pointer"
        }}
             onClick={handleClose}>
          <Icon name={'icon-close-circle'} size={24} color={"#FF7244"}/>
        </div>
      </DialogTitle>

      <DialogContent style={{
        width: 320,
        padding: "0 16px 16px"
      }}>
        <div className={style.taskAddModal__inputs}>
          <TextField
            value={title}
            onChange={e => setTitle(e.target.value)}
            label={profile.language_code === 'ru' ? "Задача" : "Task"}
            variant="outlined"
            placeholder={profile.language_code === 'ru' ? "Сходить в ..." : "Go to the..."}
            error={!!titleError}
            helperText={titleError}
          />

          <FormControl>
            <InputLabel>{profile.language_code === 'ru' ? `Приоритет` : `Priority`}</InputLabel>
            <Select
              label={profile.language_code === 'ru' ? "Приоритет" : "Priority"}
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            >
              <MenuItem value="LOW">{profile.language_code === 'ru' ? "Низкий" : "Low"}</MenuItem>
              <MenuItem value="MID">{profile.language_code === 'ru' ? "Средний" : "Medium"}</MenuItem>
              <MenuItem value="HIGH">{profile.language_code === 'ru' ? "Высокий" : "High"}</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterLuxon}
                                adapterLocale={profile.language_code}
          >
            <DatePicker
              label={profile.language_code === 'ru' ? "Дата" : "Date"}
              value={luxonDate}
              onChange={setDatesFromLuxonDate}
              format="y/LL/dd"
              showDaysOutsideCurrentMonth={true}
            />
          </LocalizationProvider>

          <FormControlLabel
            control={
              <Switch
                checked={timeEnabled}
                color="success"
                onChange={(e) => setTimeEnabled(e.target.checked)}
              />
            }
            label={profile.language_code === 'ru' ? "Установить время" : "Set time"}
          />

          {timeEnabled && (
            <LocalizationProvider width={"100%"} dateAdapter={AdapterDayjs}
                                  adapterLocale={profile.language_code === 'ru' ? 'ru-RU' : 'en'}>
              <TimePicker
                label={profile.language_code === 'ru' ? "Время" : "Time"}
                value={dateTime}
                onChange={setDateTime}
                ampm={profile.language_code !== 'ru'}
                viewRenderers={{
                  hours: renderMultiSectionDigitalClockTimeView,
                  minutes: renderMultiSectionDigitalClockTimeView,
                  seconds: renderMultiSectionDigitalClockTimeView,
                }}
              />
            </LocalizationProvider>
          )}

          <FormControlLabel
            disabled={!timeEnabled}
            control={
              <Switch
                checked={reminderEnabled}
                color={'success'}
                onChange={(e) => setReminderEnabled(e.target.checked)}
              />
            }
            label={profile.language_code === 'ru' ? "Нужно напоминание" : "Need a reminder"}
          />
          {reminderEnabled && (
            <FormControl>
              <InputLabel>{profile.language_code === 'ru' ? `Напоминание` : `Reminder`}</InputLabel>
              <Select
                disabled={!timeEnabled}
                label={profile.language_code === 'ru' ? "Напоминание" : "Reminder"}
                value={reminder}
                onChange={(e) => setReminder(e.target.value)}
              >
                <MenuItem value={15 * 60}>{profile.language_code === 'ru' ? `15 минут` : `in 15 minutes`}</MenuItem>
                <MenuItem value={30 * 60}>{profile.language_code === 'ru' ? `30 минут` : `in 30 minutes`}</MenuItem>
                <MenuItem value={60 * 60}>{profile.language_code === 'ru' ? `60 минут` : `in 60 minutes`}</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>
      </DialogContent>

      <DialogActions style={{
        padding: "0 16px 16px"
      }}>
        <Button onClick={handleSubmit} variant="contained" color="primary" style={{
          width: "100%",
          color: "white",
          fontSize: 14,
          textTransform: "none",
        }}>
          {task ? profile.language_code === 'ru' ? "Изменить задачу" : "Edit a task" : profile.language_code === 'ru' ? "Создать задачу" : "Create a task"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
