import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {Link} from "react-router";
import {useProfile} from "../../hooks/useProfile";
import {TaskAddModal} from "../Dashborad/components/TaskList/components/TaskAddModal";
import {Icon} from "../../components/Icon";
import style from "./CalendarTask.module.scss";
import dayjs from "dayjs";
import TaskApi, {sortTasks} from "../../api/task";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import {DATE_FORMAT} from "../../api";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale)

export const CustomCalendar = ({selectedDate, onDateChange, taskCounts, languageCode}) => {
  const startOfMonth = selectedDate.startOf('month');
  const endOfMonth = selectedDate.endOf('month');
  const daysInMonth = endOfMonth.date();

  // Преобразуем `day()` для раскладки с понедельника: 0 = понедельник, ..., 6 = воскресенье
  const getDayOfWeek = (day) => (day === 0 ? 6 : day - 1);

  const startDayOfWeek = getDayOfWeek(startOfMonth.day());
  const endDayOfWeek = getDayOfWeek(endOfMonth.day());

  const weeks = [];
  let currentWeek = [];

  // Заполняем первую неделю оставшимися днями предыдущего месяца
  if (startDayOfWeek !== 0) {
    const prevMonthEnd = startOfMonth.subtract(1, 'day');
    for (let i = startDayOfWeek - 1; i >= 0; i--) {
      currentWeek.push(prevMonthEnd.subtract(i, 'days'));
    }
  }

  for (let day = 1; day <= daysInMonth; day++) {
    currentWeek.push(startOfMonth.date(day));
    if (currentWeek.length === 7 || day === daysInMonth) {
      weeks.push(currentWeek);
      currentWeek = [];
    }
  }

  // Заполняем последнюю неделю днями следующего месяца
  if (endDayOfWeek !== 6) {
    for (let i = 1; i <= 6 - endDayOfWeek; i++) {
      currentWeek.push(endOfMonth.add(i, 'days'));
    }
    weeks.push(currentWeek);
  }

  const isCurrentDay = (day) => day.isSame(dayjs(), 'day');
  const isCurrentMonth = (day) => day.isSame(selectedDate, 'month');

  const monthText = (month) => {
    let months = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь',];
    if (languageCode !== 'ru') {
      months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december',];
    }
    return months[Number(month) - 1].charAt(0).toUpperCase() + months[Number(month) - 1].slice(1);
  };
  const daysWeek = () => {
    let days = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
    if (languageCode !== 'ru') {
      days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
    }
    return days;
  };

  return (<div className={style.calendarWorkspace}>

    <Box className={style.calendarDateMonth}>
      <Button onClick={() => onDateChange(selectedDate.subtract(1, 'month'))}>
        <Icon name={'chevron-left'} size={24}/>
      </Button>
      <Typography variant="h6" style={{
        color: "#828282", fontSize: 20,
      }}>
        {monthText(selectedDate.format('MM'))} {selectedDate.format('YYYY')}
      </Typography>

      <Button onClick={() => onDateChange(selectedDate.add(1, 'month'))}>
        <Icon name={'chevron-right'} size={24}/>
      </Button>

    </Box>

    <Box display="grid" gridTemplateColumns="repeat(7, 1fr)" gap={1}>
      {daysWeek().map((day) => (<Typography key={day} align="center" variant="body2" fontWeight="bold" style={{
        color: "#FF7244", fontWeight: 400
      }}>
        {day}
      </Typography>))}
      {weeks.flat().map((day, index) => (<Box
        key={index}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        border={day && day.isSame(selectedDate, 'day') ? '2px solid #5E90F2' : isCurrentDay(day) ? '2px solid #FF6B00' : '2px solid white'}
        borderRadius={1}
        p={1}
        onClick={() => onDateChange(day)}
        style={{
          cursor: 'pointer',
          height: 38,
          width: 38,
          color: isCurrentMonth(day) ? 'black' : 'lightgray',
          position: 'relative',
        }}
      >
        <span>{day.date()}</span>

        {!!taskCounts[day.date()] && isCurrentMonth(day) && (<div className={style.calendarMarkupDay}>
                <span style={{fontSize: 12}}>
                  {taskCounts[day.date()]}
                </span>
        </div>)}
      </Box>))}
    </Box>
  </div>);
}

export const CalendarTask = () => {
  const {profile, auth, initData} = useProfile();

  const taskApi = new TaskApi({profile, auth, initData})

  const [taskCounts, setTaskCounts] = useState([]);
  const [tasks, setTasks] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [selectedDate, setSelectedDate] = useState(dayjs());

  const [selectedTask, setSelectedTask] = useState(null);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);

  const currentDateRef = useRef(dayjs());

  useEffect(() => {
    taskApi.getQuantity({
      date_from: selectedDate.startOf('month').startOf('day').format(DATE_FORMAT),
      date_to: selectedDate.startOf('month').startOf('day').add(1, 'month').format(DATE_FORMAT),
    }).then((response) => {
      setTaskCounts(response.data.data);
    });
  }, [isUpdate, selectedDate]);

  useEffect(() => {
    taskApi.getList({
      date_from: selectedDate.startOf('day').format(DATE_FORMAT),
      date_to: selectedDate.startOf('day').add(1, 'day').format(DATE_FORMAT),
    }).then((response) => {
      setTasks(response.data.data.sort(sortTasks))
    });
  }, [isUpdate, selectedDate]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setActionMenuOpen(true);
  };

  const handleCloseActionMenu = () => {
    setActionMenuOpen(false);
    setSelectedTask(null);
  };

  const checkOrUncheckTask = (taskId, isChecked) => {
    (isChecked ? taskApi.uncheck(taskId) : taskApi.check(taskId)).then(() => {
      setIsUpdate(!isUpdate);
      handleCloseActionMenu();
    });
  };

  const replaceTaskForTomorrow = () => {
    taskApi.edit(selectedTask.id, {
      time: selectedTask.time,
      priority_type: selectedTask.priority_type,
      is_remind: selectedTask.is_remind,
      title: selectedTask.title,
      date: dayjs(selectedTask.date).add(1, 'day').hour(0).minute(0).second(0).format(DATE_FORMAT),
      datetime: selectedTask.datetime ? dayjs(selectedTask.datetime).add(1, 'day') : null,
      priority: selectedTask.priority,
      remind_before_seconds: selectedTask.remind_before_seconds ?? null,
      is_datetime_with_timezone: true,
    }).then(() => {
      setIsUpdate(!isUpdate);
      handleCloseActionMenu();
    });
  };

  const deleteTask = (id) => {
    taskApi.delete(id).then(() => {
      setIsUpdate(!isUpdate);
      handleCloseActionMenu();
    });
  };

  return (<>
    <div className={style.calendarContainer}>
      <div className={style.calendarMenu}>
        <div className={style.calendarMenu__cursor}>
          <Link to={"/dashboard"}>
            <Icon
              name={"icon-round-arrow-left"}
              size={24}
              color={"#A4A4A4"}
            />
          </Link>
        </div>
        <span className={style.calendarMenu__text}>
            {profile.language_code === "ru" ? "Календарь" : "Calendar"}
          </span>
        <div
          className={style.calendarMenu__cursor}
          onClick={() => setIsModalAdd(true)}
        >
          <Icon name={"icon-add-circle"} size={24} color={"#FF7244"}/>
        </div>
      </div>
      <CustomCalendar
        selectedDate={selectedDate}
        onDateChange={handleDateChange}
        taskCounts={taskCounts}
        languageCode={profile.language_code}
      />

      <div
        style={{
          border: currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "2px solid #FF713C" : "2px solid #c4c4c4",
          color: currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "#FF713C" : "#202020",
        }}
        className={style.calendarDateCurrent}
      >
        <div
          style={{
            display: "flex", justifyContent: "center",
          }}
        >
            <span style={{marginRight: 4}}>
              {profile.language_code === "ru" ? currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "Сегодня, " : "" : currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "Today is " : ""}
            </span>
          {selectedDate.format("DD.MM.YYYY")}
        </div>
      </div>

      <div>
        {!!tasks && tasks.map((task) => (<div key={task.id} className={style.task}>
          <Checkbox
            checked={!!task.checked}
            color={"success"}
            className={style.task__checkbox}
            onClick={() => checkOrUncheckTask(task.id, !!task.checked)}
          />
          <div
            style={{
              paddingTop: 8,
            }}
            onClick={() => handleTaskClick(task)}
          >
            {!!task.datetime && (<span
              style={{
                marginRight: 4, fontWeight: 700, color: "#7447FF",
              }}
            >{dayjs(task.datetime).format(profile.language_code === "ru" ? 'HH:mm' : 'hh:mm A')}</span>)}
            {task.title}
          </div>
        </div>))}
      </div>

      {!!selectedTask && (<Dialog
        open={actionMenuOpen}
        onClose={handleCloseActionMenu}
        style={{
          borderRadius: 21,
        }}
      >
        <DialogTitle className={style.dialogTitle}>
          <div
            style={{
              height: 24,
            }}
          >
            {profile.language_code === "ru" ? "Действия с задачей" : "Actions with the task"}
          </div>
          <div
            style={{
              height: 24, cursor: "pointer",
            }}
            onClick={handleCloseActionMenu}
          >
            <Icon name={"icon-close-circle"} size={24} color={"#FF7244"}/>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            width: 320,
          }}
        ></DialogContent>
        <DialogActions
          className={style.dialogActions}
          style={{justifyContent: "space-evenly"}}
        >
          <div
            style={{
              display: "flex", flexDirection: "column", alignItems: "center",
            }}
            onClick={() => checkOrUncheckTask(selectedTask.id, !!selectedTask.checked)}
          >
            <Icon
              name={!!selectedTask.checked ? "icon-close-circle" : "icon-check-circle"}
              size={48}
              color={!!selectedTask.checked ? "#A4A4A4" : "#A4A4A4"}
            />
            <div
              style={{
                fontSize: 14,
              }}
            >
              {!!selectedTask.checked ? profile.language_code === "ru" ? "Не сделано" : "Not done" : profile.language_code === "ru" ? "Сделано" : "Done"}
            </div>
          </div>
          <div
            style={{
              display: "flex", flexDirection: "column", alignItems: "center",
            }}
            onClick={() => replaceTaskForTomorrow(selectedTask.uuid)}
          >
            <Icon name="icon-undo-left" size={48}/>
            <div
              style={{
                fontSize: 14,
              }}
            >
              {profile.language_code === "ru" ? "Завтра" : "Tomorrow"}
            </div>
          </div>
          <div
            style={{
              display: "flex", flexDirection: "column", alignItems: "center",
            }}
            onClick={() => deleteTask(selectedTask.id)}
          >
            <Icon name={"icon-archive-down"} size={48}/>
            <div
              style={{
                fontSize: 14,
              }}
            >
              {profile.language_code === "ru" ? "Удалить" : "Delete"}
            </div>
          </div>
          <div
            style={{
              display: "flex", flexDirection: "column", alignItems: "center",
            }}
            onClick={() => {
              setModalEdit(selectedTask);
              setOpenEditModal(true)
            }}
          >
            <Icon name={"icon-eraser"} size={48} color={"#A4A4A4"}/>
            <div
              style={{
                fontSize: 14,
              }}
            >
              {profile.language_code === "ru" ? "Изменить" : "Change"}
            </div>
          </div>
        </DialogActions>
      </Dialog>)}
    </div>

    <TaskAddModal
      open={isModalAdd}
      successfulResponse={() => setIsUpdate(!isUpdate)}
      handleClose={() => setIsModalAdd(false)}
      selectedDate={selectedDate}
    />

    <TaskAddModal
      open={openEditModal}
      handleClose={() => setOpenEditModal(false)}
      successfulResponse={() => {
        setIsUpdate(!isUpdate)
        handleCloseActionMenu()
      }}
      task={modalEdit}
    />
  </>);
}
