import axios from "axios";
import {ROOT_URL} from "../config/url";
import dayjs from "dayjs";

export function sortTasks(taskA, taskB) {
  if (taskA.datetime && taskB.datetime) {
    return dayjs(taskA.datetime) > dayjs(taskB.datetime) ? 1 : -1
  }

  if (taskA.datetime) {
    return -1
  }

  if (taskB.datetime) {
    return 1
  }

  return taskA.id - taskB.id
}

export default class TaskApi {
  constructor(props) {
    this.api = axios.create({
      baseURL: ROOT_URL + '/api/v2/tasks',
      withCredentials: false,
      headers: {
        'X-Telegram-Auth-Date': props.auth.auth_date,
        'X-Telegram-Hash': props.auth.hash,
        'X-Telegram-Init-Data': btoa(props.initData),
        'User': props.profile.uuid,
        'Content-Type': 'application/json',
      },
    })
  }

  getList(params) {
    return this.api.get('', {params});
  }

  getQuantity(params) {
    return this.api.get('/quantity', {params});
  }

  add(data, params = {}) {
    return this.api.post('', data, {params});
  }

  edit(id, data, params = {}) {
    return this.api.put(id.toString(), data, {params});
  }

  check(id) {
    return this.api.post(id + '/check');
  }

  uncheck(id) {
    return this.api.post(id + '/uncheck');
  }

  delete(id) {
    return this.api.delete(id.toString());
  }
}
