import React, {useEffect, useState} from "react";
import style from "./WaterList.module.scss";
import {WaterGlass} from "./WaterGlass";
import {useProfile} from "../../../../hooks/useProfile";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";
import moment from "moment/moment";

export const WaterGlasses = ({selectedDate, waterMarksQuantity, setWaterMarksQuantity}) => {
  const {profile, auth, initData} = useProfile();
  const [isUpdate, setIsUpdate] = useState(false);

  const handleMarkWater = (isChecked) => {
    let promise;

    if (isChecked) {
      promise = axios.put('/api/v1/water-mark/uncheck', {
        date: selectedDate.format("YYYY-MM-DD")
      }, {
        withCredentials: false, baseURL: ROOT_URL, headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
      })
    } else {
      promise = axios.post('/api/v1/water-mark/check', {
        date: selectedDate.format("YYYY-MM-DD")
      }, {
        withCredentials: false, baseURL: ROOT_URL, headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
      })
    }

    promise.then(() => {
      setIsUpdate(!isUpdate)
    })
  }

  useEffect(() => {
    axios.get('/api/v1/water-marks/day', {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth.auth_date,
        'X-Telegram-Hash': auth.hash,
        'X-Telegram-Init-Data': btoa(initData),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      }, params: {
        date: selectedDate.format("YYYY-MM-DD")
      }
    }).then((response) => {
      setWaterMarksQuantity(response.data.data.length > 0 ? response.data.data[0]?.count_marks : 0)
    })
  }, [isUpdate, selectedDate])

  return (<div className={style.waterGlasses}>
    {[...Array(10)].map((x, i) => <WaterGlass key={i} isFull={waterMarksQuantity > i}
                                              isFirstEmpty={i === waterMarksQuantity}
                                              handleMarkWater={handleMarkWater}/>)}
  </div>)
}
