import React from "react";
import {Navigate, Route, Routes} from "react-router";
import {Dashboard} from "./Dashborad/Dashboard";
import {CalendarTask} from "./CalendarTask/CalendarTask";
import {ProfileForm} from "./ProfileForm/ProfileForm";
import Header from "../components/Header/Header";
import {CalendarHabits} from "./CalendarHabits/CalendarHabits";
import {CalendarWater} from "./CalendarWater/CalendarWater";
import style from './Routing.module.scss'

export const Routing = () => {
  return (<div
    style={{
      background: "linear-gradient(to bottom, #dff5f9, #d5e4fd)",
    }}
  >
    <div
      className={style.appContainer}>
      <Header/>

      <Routes>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/calendar-task" element={<CalendarTask/>}/>
        <Route path="/calendar-habits" element={<CalendarHabits/>}/>
        <Route path="/calendar-water" element={<CalendarWater/>}/>
        <Route path="/profile-form" element={<ProfileForm/>}/>
        {/*<Route path="/help" element={<WorkyHelp/>}/>*/}
        {/*<Route path="/referal" element={<EarnMoney/>}/>*/}
        <Route path="/" element={<Navigate to="/dashboard"/>}/>
      </Routes>
    </div>
  </div>);
};
