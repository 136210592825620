import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import moment from "moment";
import {
  Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from "@mui/material";
import {Link} from "react-router";
import {useProfile} from "../../hooks/useProfile";
import {Icon} from "../../components/Icon";
import style from "../CalendarTask/CalendarTask.module.scss"; // используем стиль из другой директории, возможнло нужно его вынести в общие стили. или как то по другому оформить
import {CustomCalendar} from "../CalendarTask/CalendarTask";
import {ROOT_URL} from "../../config/url";
import {HabitEditModal} from "../Dashborad/components/HabitList/components/HabitEditModal";
import {HabitAddModal} from "../Dashborad/components/HabitList/components/HabitAddModal";
import dayjs from "dayjs";

export const CalendarHabits = () => {
  const {profile, auth, initData} = useProfile();
  const [habitsCount, setHabitsCounts] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [habits, setHabits] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const currentDateRef = useRef(dayjs());
  const [modalEdit, setModalEdit] = useState(null);
  const [isModalAdd, setIsModalAdd] = useState(false);

  const [selectedHabit, setSelectedHabit] = useState(null);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleTaskClick = (task) => {
    setSelectedHabit(task);
    setActionMenuOpen(true);
  };

  const handleCloseActionMenu = () => {
    setActionMenuOpen(false);
    setSelectedHabit(null);
  };

  useEffect(() => {
    axios.get('/api/v1/habits/month', {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth.auth_date,
        'X-Telegram-Hash': auth.hash,
        'X-Telegram-Init-Data': btoa(initData),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      }, params: {
        year: selectedDate.format("YYYY"), month: selectedDate.format("MM")
      }
    }).then((response) => {
      if (!!response.data.data.habits_by_day) {
        const counts = response.data.data.habits_by_day.reduce((acc, {day, habit_count}) => {
          acc[day] = habit_count;
          return acc;
        }, {});
        setHabitsCounts({...counts});
      } else {
        setHabitsCounts({});
      }
    });
  }, [isUpdate, selectedDate]);

  useEffect(() => {
    axios.get('/api/v1/habits/day', {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth.auth_date,
        'X-Telegram-Hash': auth.hash,
        'X-Telegram-Init-Data': btoa(initData),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      }, params: {
        date: selectedDate.format("YYYY-MM-DD")
      }
    }).then((response) => {
      setHabits(response.data.data.map((habit) => ({
        ...habit,
        isChecked: habit.habit_marks.some((mark) => moment(mark.date).format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD")),
      })));
    });
  }, [isUpdate, selectedDate]);

  const handleDeleteChange = (habitUuid) => {
    axios.delete('/api/v1/habit/' + habitUuid + '/delete', {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth.auth_date,
        'X-Telegram-Hash': auth.hash,
        'X-Telegram-Init-Data': btoa(initData),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      }, params: {
        date: moment().format("YYYY-MM-DD")
      }
    }).then((response) => {
      setIsUpdate(!isUpdate);
      handleCloseActionMenu();
    });
  };

  const handleCheckboxChange = (habitUuid) => {
    axios.post('/api/v1/habit/' + habitUuid + '/check', {
      date: selectedDate.format("YYYY-MM-DD")
    }, {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth.auth_date,
        'X-Telegram-Hash': auth.hash,
        'X-Telegram-Init-Data': btoa(initData),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      setIsUpdate(!isUpdate);
      handleCloseActionMenu();
    });
  };

  return (<>
    <div className={style.calendarContainer}>
      <div className={style.calendarMenu}>
        <div className={style.calendarMenu__cursor}>
          <Link to={"/dashboard"}>
            <Icon
              name={"icon-round-arrow-left"}
              size={24}
              color={"#A4A4A4"}
            />
          </Link>
        </div>
        <span className={style.calendarMenu__text}>
            {profile.language_code === "ru" ? "Привычки дня" : "Habits of the day"}
          </span>
        <div
          className={style.calendarMenu__cursor}
          onClick={() => setIsModalAdd(true)}
        >
          <Icon name={"icon-add-circle"} size={24} color={"#FF7244"}/>
        </div>
      </div>
      <CustomCalendar
        selectedDate={selectedDate}
        onDateChange={handleDateChange}
        taskCounts={habitsCount}
        languageCode={profile.language_code}
      />
      <div
        style={{
          border: currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "2px solid #FF713C" : "2px solid #c4c4c4",
          color: currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "#FF713C" : "#202020",
        }}
        className={style.calendarDateCurrent}
      >
        <div
          style={{
            display: "flex", justifyContent: "center",
          }}
        >
            <span style={{marginRight: 4}}>
              {profile.language_code === "ru" ? currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "Сегодня, " : "" : currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "Today is " : ""}
            </span>
          {selectedDate.format("DD.MM.YYYY")}
        </div>
      </div>

      <div>
        {!!habits && habits.map((habit) => (<div key={habit.uuid} className={style.task}>
          <Checkbox
            checked={habit.isChecked}
            stype={'' + habit.isChecked}
            color={"success"}
            className={style.task__checkbox}
            onClick={() => handleCheckboxChange(habit.uuid)}
          />
          <div
            style={{
              paddingTop: 8,
            }}
            onClick={() => handleTaskClick(habit)}
          >
            {!!habit.time && (<span
              style={{
                marginRight: 4, fontWeight: 700, color: "#7447FF",
              }}
            >
                      {habit.time}
                    </span>)}
            {habit.title}
          </div>
        </div>))}
      </div>
    </div>

    {!!selectedHabit && (<Dialog open={actionMenuOpen} onClose={handleCloseActionMenu} style={{
      borderRadius: 21
    }}>
      <DialogTitle className={style.dialogTitle}>
        <div style={{
          height: 24,
        }}>{profile.language_code === 'ru' ? 'Действия с привычкой' : 'Actions with the habit'}</div>
        <div style={{
          height: 24, cursor: "pointer"
        }}
             onClick={handleCloseActionMenu}>
          <Icon name={'icon-close-circle'} size={24} color={"#FF7244"}/>
        </div>
      </DialogTitle>
      <DialogContent style={{
        width: 320,
      }}></DialogContent>
      <DialogActions className={style.dialogActions} style={{justifyContent: "space-evenly"}}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
             onClick={() => handleCheckboxChange(selectedHabit.uuid)}>
          <Icon name={!!selectedHabit.checked_at ? 'icon-close-circle' : 'icon-check-circle'} size={48} color={
            !!selectedHabit.checked_at ? '#A4A4A4' : '#A4A4A4'
          }/>
          <div style={{
            fontSize: 14
          }}>
            {!!selectedHabit.isChecked ? profile.language_code === 'ru' ? 'Не сделано' : 'Not done' : profile.language_code === 'ru' ? 'Сделано' : 'Done'}
          </div>
        </div>

        <div style={{
          display: "flex", flexDirection: "column", alignItems: "center",
        }}
             onClick={() => handleDeleteChange(selectedHabit.uuid)}>
          <Icon name={'icon-archive-down'} size={48}/>
          <div style={{
            fontSize: 14
          }}>
            {profile.language_code === 'ru' ? "Удалить" : "Delete"}
          </div>
        </div>

        <div style={{
          display: "flex", flexDirection: "column", alignItems: "center",
        }}
             onClick={() => setModalEdit(selectedHabit)}>
          <Icon name={'icon-eraser'} size={48} color={'#A4A4A4'}/>
          <div style={{
            fontSize: 14
          }}>
            {profile.language_code === 'ru' ? "Изменить" : "Change"}
          </div>
        </div>
      </DialogActions>
    </Dialog>)}

    <HabitEditModal
      open={!!modalEdit}
      handleClose={() => setModalEdit(null)}
      object={modalEdit}
      successfulResponse={() => setIsUpdate(!isUpdate)}
    />
    <HabitAddModal
      open={isModalAdd}
      successfulResponse={() => setIsUpdate(!isUpdate)}
      handleClose={() => setIsModalAdd(false)}
    />
  </>);
}
