import {useContext, useEffect, useReducer, useState} from 'react'
import {ProfileContext} from "../contexts/profile";

export const useProfile = () => {
  const {profileState, profileDispatch} = useContext(ProfileContext);
  const [profile, setProfile] = useState(profileState.profileData);
  const [auth, setAuth] = useState(profileState.telegramAuth);
  const [initData, setInitData] = useState(profileState.telegramInitData);
  const [user, setUser] = useState(profileState.telegramUser);

  useEffect(() => {
    setProfile(profileState.profileData);
  }, [profileState.profileData]);

  useEffect(() => {
    setAuth(profileState.telegramAuth);
  }, [profileState.telegramAuth]);

  useEffect(() => {
    setInitData(profileState.telegramInitData);
  }, [profileState.telegramInitData]);

  useEffect(() => {
    setUser(profileState.telegramUser);
  }, [profileState.telegramUser]);

  return {
    profile,
    auth,
    initData,
    user,
    profileState,
    profileDispatch
  }
}
