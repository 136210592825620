import React from "react";
import style from "./WaterList.module.scss";
import {Icon} from "../../../../components/Icon";
import {useProfile} from "../../../../hooks/useProfile";

export const WaterGlass = ({isFull, isFirstEmpty, handleMarkWater}) => {
  const {profile} = useProfile();

  return (<div className={style.waterGlass} onClick={() => handleMarkWater(isFull)}>
    {isFull && <Icon name={"icon-water-glass-full"} height={40} color={"#A4A4A4"}/>}

    {!isFull && <>
      {isFirstEmpty &&
        <p className={style.waterGlass__text}>+252<br/>{profile.language_code === 'ru' ? 'мл' : 'ml'}</p>}
      <Icon name={"icon-water-glass-empty"} height={40} color={"#A4A4A4"}/>
    </>}
  </div>)
}
